<template>
  <div>
    <el-form class="query-form" size="small" :inline="true" ref="searchForm" :model="searchForm"
             label-width="88px">
      <el-form-item prop="keyWord" label="输入搜索：">
        <el-input v-model.trim="searchForm.keyWord" maxlength="50" placeholder="请输入关键字（50字内）"
                  clearable></el-input>
      </el-form-item>
      <el-form-item prop="createStartDate" label="收藏时间：">
        <el-date-picker v-model="createDate" value-format="yyyy-MM-dd HH:mm:ss"
                        format="yyyy-MM-dd HH:mm:ss"
                        type="datetimerange" clearable start-placeholder="开始时间"
                        end-placeholder="结束时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="search()" size="small">查询</el-button>
        <el-button @click="reset()" size="small" icon="el-icon-refresh-right">重置</el-button>
      </el-form-item>
      <el-button class="f_r" type="primary" size="small" icon="el-icon-star-off"
                 v-if="pageType != 'admin'"
                 v-show="hasPermission('personal:collect:cancel')" @click="cancel()">取消收藏
      </el-button>
    </el-form>
    <div class="bg-white">
      <el-table
          v-show="searchForm.favoritesType == 0"
          :data="dataList"
          v-loading="loading"
          size="small"
          height="calc(100vh - 400px)"
          @selection-change="selectionChangeHandle"
          class="table" ref="multipleTable" row-key='archivesPersonalFavoritesId'>
        <el-table-column type="selection" :reserve-selection='true' fixed/>
        <el-table-column prop="archivesPersonalFavoritesUserName" label="收藏" v-if="pageType == 'admin'" show-overflow-tooltip></el-table-column>
        <el-table-column prop="createDate" label="收藏时间" min-width="120" sortable
                         show-overflow-tooltip></el-table-column>
        <el-table-column prop="archivesDataName" label="题名" min-width="120" show-overflow-tooltip/>
        <el-table-column prop="warehousingState" label="状态" min-width="120" show-overflow-tooltip></el-table-column>
        <el-table-column prop="quanzongNumber" label="全宗号" min-width="120"
                         show-overflow-tooltip></el-table-column>
        <el-table-column prop="quanzongName" label="全宗名称" min-width="120"
                         show-overflow-tooltip></el-table-column>
        <el-table-column prop="reference" label="档号" min-width="120"
                         show-overflow-tooltip></el-table-column>
        <el-table-column v-for="item in config" :key="item.archivesBasicId"
                         :prop="item.code"
                         :label="item.forName ? item.forName : item.basicName"
                         v-if="item.dataSelect == 0"
                         show-overflow-tooltip min-width="120">
          <template slot="header" slot-scope="scope">
            <el-tooltip class="item" effect="dark" :content="item.forName ? item.forName : item.basicName"
                        placement="top-start">
              <span class="omit">{{ item.forName ? item.forName : item.basicName }}</span>
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            <span
                v-if="item.archivesBasicTypeCode == 'date'">{{
                metadataDateFormatValue(item, scope.row.archivesData[item.code])
              }}</span>
            <span v-else>{{ scope.row.archivesData[item.code] }}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" width="150" label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" v-show="hasPermission('personal:collect:view')"
                       @click="view(scope.row, 'view')">详情
            </el-button>
            <el-button type="text" size="small" v-show="hasPermission('personal:collect:cancel')"
                       v-if="pageType != 'admin'"
                       @click="cancel(scope.row.archivesPersonalFavoritesId)">取消收藏
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-table
          v-show="searchForm.favoritesType == 1"
          :data="dataList"
          v-loading="loading"
          size="small"
          height="calc(100vh - 400px)"
          @selection-change="selectionChangeHandle"
          class="table" ref="multipleTable1" row-key='id'>
        <el-table-column type="selection" :reserve-selection='true' fixed/>
        <el-table-column prop="archivesPersonalFavoritesUserName" label="收藏" v-if="pageType == 'admin'" show-overflow-tooltip></el-table-column>
        <el-table-column prop="createDate" label="收藏时间" min-width="120" sortable
                         show-overflow-tooltip></el-table-column>
        <el-table-column prop="name" label="图标" width="120px">
          <template slot-scope="scope">
            <div class="flex_a_c">
              <div v-if="scope.row.carded == 0" class="icon-wenwukapian iconfont listIcon"></div>
              <el-image
                  style="width: 36px; height: 36px"
                  :src="scope.row.picMasterViewUrl"
                  :fit="'cover'"
                  :preview-src-list="[scope.row.picMasterViewUrl]"
              >
                <div slot="error" class="image-slot">
                  <el-image
                      style="width: 36px; height: 36px"
                      :src="require('@/assets/img/default.png')"
                      :fit="'cover'">
                  </el-image>
                </div>
              </el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="collectionName" label="藏品名称"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <div v-html="scope.row.collectionName"></div>
          </template>
        </el-table-column>
        <el-table-column prop="helpNum" label="辅助账编号" show-overflow-tooltip>
          <template slot-scope="scope">
            <div v-html="scope.row.helpNum"></div>
          </template>
        </el-table-column>
        <el-table-column prop="generalNum" label="总账编号" show-overflow-tooltip>
          <template slot-scope="scope">
            <div v-html="scope.row.generalNum"></div>
          </template>
        </el-table-column>
        <el-table-column prop="integrityInfo" label="完残情况" show-overflow-tooltip>
          <template slot-scope="scope">
            <div v-html="scope.row.integrityInfo"></div>
          </template>
        </el-table-column>
        <el-table-column prop="integrity" label="完残程度" show-overflow-tooltip>
          <template slot-scope="scope">
            <div v-html="scope.row.integrity"></div>
          </template>
        </el-table-column>
        <el-table-column prop="cstoreState" label="库存状态" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ $dictUtils.getDictLabel("cStore_state", scope.row.cstoreState, '-') }}
          </template>
        </el-table-column>
        <!--            <el-table-column v-for="(item, index) in collConfig" :key="item.archivesBasicId"-->
        <!--                             :prop="item.code"-->
        <!--                             :sortable="item.archivesBasicTypeCode == 'date' || item.archivesBasicTypeCode == 'integer' ? 'custom' : false"-->
        <!--                             :label="item.basicName" v-if="item.dataSelect == 0" min-width="120"-->
        <!--                             show-overflow-tooltip>-->
        <!--                <template slot-scope="scope">-->
        <!--                    <div v-html="scope.row.archivesData[item.code]"></div>-->
        <!--                </template>-->
        <!--            </el-table-column>-->
        <el-table-column prop="createUserName" label="创建者"
                         show-overflow-tooltip></el-table-column>
        <el-table-column prop="createDate" sortable label="创建时间" width="150px">
          <template slot-scope="scope">
            {{ scope.row.createDate | formatDate }}
          </template>
        </el-table-column>
        <el-table-column prop="updateUserName" label="最后更新人"
                         show-overflow-tooltip></el-table-column>
        <el-table-column prop="updateDate" sortable label="最后更新时间" width="150px"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.updateDate | formatDate }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" width="150" label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" v-show="hasPermission('personal:collect:view')"
                       @click="view(scope.row, 'view')">详情
            </el-button>
            <el-button type="text" size="small" v-show="hasPermission('personal:collect:cancel')"
                       v-if="pageType != 'admin'"
                       @click="cancel(scope.row.archivesPersonalFavoritesId)">取消收藏
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="searchForm.current"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="searchForm.size"
          :total="total"
          background
          layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <RecordDetails ref="recordDetails" @refreshDataList="search()"></RecordDetails>
  </div>
</template>

<script>
import RecordDetails from "@/views/modules/record/borrow/components/recordDetails.vue";

export default {
  props: ['pageType','favoritesType'],
  components: {RecordDetails},
  data() {
    return {
      searchForm: {
        favoritesType: '0',
        keyWord: '',
        createStartDate: '',
        createEndDate: '',
        current: 1,
        size: 10,
      },
      current2:'',
      createDate: [],
      loading: false,
      dataList: [],
      total: 0,
      dataListSelections: [],
      config: [],
      collConfig: [],
    }
  },

  mounted() {
    let listSearch = JSON.parse(sessionStorage.getItem('listSearch'))
    if (listSearch) {
      this.searchForm = listSearch.searchForm
      this.current2 = listSearch.searchForm.current
    }
    this.getAllData()
    this.selectCascader()
  },

  methods:{
    // 多选
    selectionChangeHandle(val) {
      this. dataListSelections= val
    },

    // 检索
    search(type,favoritesType) {
      if (favoritesType || favoritesType == 0){
        this.searchForm.favoritesType = favoritesType
      }
      this.$emit('refresh')
      if (type == 1) {
        this.searchForm.current = 1
      }
      if (type == 0) {
        this.$refs.multipleTable.clearSelection()
        this.$refs.multipleTable1.clearSelection()
      }
      this.loading = true
      let p = JSON.parse(JSON.stringify(this.searchForm))
      p.current = this.current2 || p.current
      p.createStartDate = this.createDate ? this.createDate[0] : ''
      p.createEndDate = this.createDate ? this.createDate[1] : ''
      p.favoritesCollection = this.pageType == 'admin' ? '0':'1'
      this.$nextTick(()=>{
        let fechUrl = this.api.record.getArchivesPersonalFavoritesByCreateBy
        if (this.favoritesType == 1){
          fechUrl = this.api.record.collectionGetArchivesPersonalFavoritesByCreateBy
        }
        this.$axios(fechUrl, p, 'post').then(res => {
          if (res.status) {
            console.log(res)
            this.dataList = res.data.records
            if(this.current2){
              this.searchForm.current = this.current2
              this.current2 = ''
            }
            if (this.dataList.length == 0 && this.searchForm.current > 1) {
              this.searchForm.current--
              this.search()
            }
            this.total = parseInt(res.data.total)
          } else {
            this.$message.error('查询失败');
          }
          this.loading = false
          this.$nextTick(() => {
            this.$refs.multipleTable.doLayout()
            this.$refs.multipleTable1.doLayout()
          })
        })
      })
    },
    // 获取必选元数据字段
    getAllData() {
      this.$axios(this.api.record.getArchivesBasicByRequired).then((res) => {
        if (res.status) {
          this.config = res.data
          this.search(1)
        }
      })
    },
    // 查看
    view(row, method) {
      if (this.searchForm.favoritesType == 0) {
        if (row.delFlag == 1) {
          this.$message.error('该档案已删除！')
          return
        }
        this.$refs.recordDetails.init(row, method, row.archivesBasicDataId, 'archivesDataId', row.archivesPersonalFavoritesId, this.pageType)
      } else {
        let listSearch = {
          searchForm:this.searchForm,
          favoritesType:this.favoritesType,
        }
        sessionStorage.setItem('listSearch', JSON.stringify(listSearch))

        this.$router.push({
          path: '/collection/accounts/addCollection',
          query: {
            butType: 3,
            id: row.archivesDataId,
            archivesBasicDataId: row.archivesBasicDataId,
            typePage: 'ledger',
            see: true
          }
        })
      }
    },

    // 取消收藏
    cancel(id) {
      let ids = [id];
      if (!id && !this.dataListSelections.length) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      if (!id) {
        ids = this.dataListSelections.map(item => {
          return item.archivesPersonalFavoritesId
        })
      }
      this.$confirm(`确定取消收藏所选项吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let fechUrl = this.api.record.archivespersonalfavoritesRemoveById
        if (this.favoritesType == 1){
          fechUrl = this.api.record.collectionArchivespersonalfavoritesRemoveById
        }
        this.$axios(fechUrl, ids, 'post').then(data => {
          if (data.status) {
            this.$message.success('取消成功')
            this.search(0)
          } else {
            this.$message.error('取消失败')
          }
        })
      })
    },
    //重置
    reset() {
      this.$refs.multipleTable.clearSelection()
      this.$refs.multipleTable1.clearSelection()
      this.$refs.searchForm.resetFields();
      this.createDate = []
      this.search(1)
    },
    // 每页数
    sizeChangeHandle(val) {
      this.searchForm.size = val
      this.searchForm.current = 1
      this.search()
    },
    // 当前页
    currentChangeHandle(val) {
      this.searchForm.current = val
      this.search()
    },

    //藏品元数据
    selectCascader() {
      let getByIdUrl = this.api.collection.getArchivesBasicByRequired
      this.$axios(getByIdUrl).then(data => {
        if (data.status) {
          this.collConfig = data.data.archivesBasicDataGroup
          this.collConfig.map(item => {
            if (item.dataWhere == 0) {
              this.$set(item, 'value', '')
              this.$set(this.inputForm, item.code, '')
              if (item.archivesBasicTypeCode == 'integer') {
                this.$set(this.rules, item.code, [{
                  validator: this.validator.isDigits,
                  trigger: 'blur'
                }])
              }
            }
          })
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
